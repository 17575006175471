<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "tanQin",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到弹琴页",
                            "#按图1说明参数:",
                            "1.必填项-弹奏类型:战斗琴或者练习琴",
                            "2.必填项-弹奏部分:分为前奏,间奏和尾曲",
                            "3.必填项-战斗琴快捷键:弹奏类型为战斗琴时候,要把对应的前奏,间奏和尾曲拖到快捷栏,并设置对应快捷键,战斗琴是" +
                            "通过按快捷键快速弹琴",
                            "4.必填项-指定弹奏坐标:战斗琴弹奏前,可以移到指定坐标后弹琴",
                            "5.必填项-邀请玩家:战斗琴弹奏前,可以邀请指定玩家,进队伍后弹琴,邀请格式是:角色A+角色B+角色C",
                            "6.必填项-死亡就近:战斗琴可以勾选死亡自动就近,配合指定弹奏坐标回到战场继续弹琴",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明-战斗琴",
                        text:[
                            '#设置好战斗琴参数后,曲谱拖拽到对应的快捷键,然后开始任务(图1)',
                            '#右上角的弹琴状态图标不可以遮挡(图2)',
                        ],
                        img:[
                            "3.png",
                            "4.png",
                        ]
                    },
                    {
                        title: "游戏说明-练习琴",
                        text:[
                            '#打开弹琴界面,选中琴谱,并在设置好练习琴参数,开始任务',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
